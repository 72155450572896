function checkTosterResponse(content, type) {
  console.log(content);
  if (type == "Success") {
    toastr.success(content);
  } else {
    toastr.error(content);
  }
}

function setShareLinks(type, pageUrl){
  if(type == "facebook") {
      url = "https://www.facebook.com/sharer.php?u=" + pageUrl;
  } else if(type == "twitter") {
      url = "https://twitter.com/intent/tweet?url=" + pageUrl;
  } else if(type == "linkedin") {
    url = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl;
  }                              
  socialWindow(url);
}

function socialWindow(url) {
  var left = (screen.width - 570) / 2;
  var top = (screen.height - 570) / 2;
  var params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
  window.open(url,"NewWindow",params);
}

function copyToClipboard(element) {
  var value = $(element).text();
  navigator.clipboard.writeText(value);
}

function showLogoutPopup() {
  $("#logout-model").css("display", "block");
}
function hideLogoutPopup() {
  $("#logout-model").css("display", "none");
}

function showPopup(id) {
  console.log(id);
  $("#" + id).css("display", "block");
}
function hidePopup(id) {
  $("#" + id).css("display", "none");
}

function startLoader() {
  $("#loader_img").css("display", "flex");
}

function stopLoader() {
  $("#loader_img").css("display", "none");
}

function iniImageMagnify(imageId) {
  console.log("imageURL");
  var gallery = "#gallery-1";
  $(gallery)
    .magnificPopup({
      delegate: "a",
      type: "image",
      gallery: {
        enabled: true,
      },
    })
    .magnificPopup("open");
}

function initDealMonthGraph(monthsData, seriesData) {
  Highcharts.chart("chart_deal_month", {
    chart: {
      type: "column",
    },
    title: {
      text: "Deal by Month",
    },
    xAxis: {
      categories: monthsData,
      crosshair: true,
      title: {
        text: 'Month',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: "<tr>" + '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Deal Count",
        data: seriesData,
      },
    ],
  });
}

function initDealMonthCity(cityData, seriesData) {
  Highcharts.chart("chart_deal_city", {
    chart: {
      type: "bar",
    },
    title: {
      text: "Deal by City",
    },
    xAxis: {
      categories: cityData,
      title: {
        text: 'City',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Numbers",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    // tooltip: {
    //   valueSuffix: "",
    // },
    tooltip: { enabled: false },
    // plotOptions: {
    //   bar: {
    //     dataLabels: {
    //       enabled: false,
    //     },
    //   },
    // },
    plotOptions: {
      pie: {
          allowPointSelect: false,
          size: '100%',
          dataLabels: { enabled: false }
      }
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      shadow: true,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: seriesData,
      },
    ],
  });
}

function initInvestorBySource(responseData) {
  console.log("responseData", responseData);
  // Make monochrome colors
  var pieColors = (function () {
    var colors = [],
      base = Highcharts.getOptions().colors[0],
      i;
    for (i = 0; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten((i - 3) / 7)
          .get()
      );
    }
    return colors;
  })();

  // Build the chart
  Highcharts.chart("investor_by_source", {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "Investor by Source",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        colors: pieColors,
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Investor Count",
        data: responseData,
      },
    ],
  });
}

function initDealPipelineGraph(responseData) {
  Highcharts.chart("deal_pipeline", {
    chart: {
      type: "funnel",
    },
    title: {
      text: "Deal Count",
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          format: "<b>{point.name}</b> ({point.y:,.0f})",
          softConnector: true,
        },
        center: ["30%", "40%"],
        neckWidth: "30%",
        neckHeight: "25%",
        width: "80%",
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Deal Count",
        data: responseData,
      },
    ],
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            plotOptions: {
              series: {
                dataLabels: {
                  inside: false,
                },
                center: ["50%", "50%"],
                width: "100%",
              },
            },
          },
        },
      ],
    },
  });
}
